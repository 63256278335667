<template>
  <div class="card card-custom">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-2"
        id="kt_wizard_ribbon"
        data-wizard-state="step-first"
        data-wizard-clickable="false"
      >
        <!--begin: Wizard Nav -->
        <div class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
          <div class="wizard-steps">
            <div class="wizard-step my-1" data-wizard-type="step" :data-wizard-state="step.state" v-for="step in steps" :key="step.title">
              <div class="wizard-wrapper">
                <div class="wizard-label">
                  <h3 class="wizard-title mb-0">
                    {{ step.title }}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body -->
        <div class="wizard-body py-8 px-8 py-lg-20 px-lg-10">
          <!--begin: Wizard Form-->
          <div class="row">
            <div class="offset-xxl-2 col-xxl-8">
              <form class="form" id="kt_form">
                
                <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                  <h1 class="mb-5 font-weight-bolder text-dark display-4">
                    Etiketi tanımlayın
                  </h1>
                  <p class="font-size-h5 mb-7">
                    Etiketin adını Türkçe ve İngilizce olarak girin.
                  </p>

                   <div class="alert alert-warning bg-light-warning my-8" role="alert" v-if="!validation.step1">
                    <div class="alert-text text-body d-flex align-items-center">
                      <span class="svg-icon svg-icon-warning pr-2">
                        <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
                      </span>
                      <span class="font-weight-bold">Devam edebilmek için lütfen etikete Türkçe ve İngilizce isim verin.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label class="text-body font-weight-bold pl-1 d-flex align-items-center">
                      Etiket
                      <Flag class="ml-2" code="TR" size="S" :hasDropShadow="true" :hasBorder="true" :hasBorderRadius="true"/>
                    </label>
                    <input type="text" class="form-control form-control-lg" placeholder="Etiketi isimlendirin" v-model="title.tr"/>
                  </div>

                  <div class="form-group">
                    <label class="text-body font-weight-bold pl-1 d-flex align-items-center">
                      Etiket
                      <Flag class="ml-2" code="GB-UKM" size="S" :hasDropShadow="true" :hasBorder="true" :hasBorderRadius="true"/>
                    </label>
                    <input type="text" class="form-control form-control-lg" placeholder="Etiketi isimlendirin" v-model="title.en"/>
                  </div>
                </div>

                <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                  <h1 class="mb-5 font-weight-bolder text-dark display-4">
                    Renk seçin
                  </h1>
                  <p class="font-size-h5 mb-7">
                    Etiketiniz için bir renk atayın.
                  </p>

                  <div class="alert alert-warning bg-light-warning my-8" role="alert" v-if="!validation.step2">
                    <div class="alert-text text-body d-flex align-items-center">
                      <span class="svg-icon svg-icon-warning pr-2">
                        <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
                      </span>
                      <span class="font-weight-bold">Devam edebilmek için lütfen bir renk seçin.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="d-flex align-items-center">
                      <div class="symbol symbol-35 cursor-pointer" @click="isColorPickerVisible = !isColorPickerVisible">
                        <span class="symbol-label rounded-right-0" :style="`background: ${color};`"></span>
                      </div>
                      <input type="text" class="form-control rounded-left-0 text-uppercase" :placeholder="`${color}`" v-model="color">
                    </div>
                    <chrome-picker :value="color" @input="updateColorValue" class="mt-4" v-if="isColorPickerVisible"/>
                  </div>
                </div>

                <!--begin: Wizard Actions -->
                <div class="d-flex justify-content-between border-top pt-10">
                  <div class="mr-2">
                    <button
                      class="btn btn-secondary font-weight-bold px-9 py-4"
                      data-wizard-type="action-prev"
                    >
                      Geri
                    </button>
                  </div>
                  <div>
                    <button
                      @click="submit"
                      class="btn btn-dark font-weight-bolder px-9 py-4"
                      :class="{ 'spinner spinner-white spinner-left mr-3': isSubmitting }"
                      ref="kt_create_provide"
                      data-wizard-type="action-submit"
                      :disabled="isSubmitting"
                    >
                      Etiketi Oluştur
                    </button>
                    <button
                      class="btn btn-dark font-weight-bolder px-9 py-4"
                      data-wizard-type="action-next"
                    >
                      Sonraki Adım
                    </button>
                  </div>
                </div>
                <!--end: Wizard Actions -->

              </form>
            </div>
            <!--end: Wizard-->
          </div>
        </div>
        <!--end: Wizard Body -->
      </div>
      <!--end: Wizard-->
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import KTWizard from "@/assets/js/components/wizard";
import { Chrome } from 'vue-color';

export default {
  components: {
    'chrome-picker': Chrome
  },
  data() {
    return {
      wizard: null,
      isColorPickerVisible: false,
      steps: [
        {
          title: 'Etiket',
          state: 'current'
        },
        {
          title: 'Renk',
          state: 'pending'
        },
      ],

      // Step 1
      title: {
        tr: '',
        en: ''
      },

      // Step 2
      color: '#' + Math.floor(Math.random()*16777215).toString(16).toUpperCase(),

      validation: {
        step1: true,
        step2: true
      },
      isSubmitting: false
    }
  },
  mounted() {
    const self = this;
    // Initialize form wizard
    this.wizard = new KTWizard("kt_wizard_ribbon", {
      startStep: 1, // initial active step number
      clickableSteps: false // allow step clicking
    });

    this.wizard.on("change", function(wizardObj) {
      if (wizardObj.newStep < wizardObj.currentStep) {
        return;
      }

      const step = wizardObj.getStep();
      switch (step) {
        case 1:
          if (self.title.tr.length === 0 || self.title.en.length === 0) {
            self.validation.step1 = false;
            wizardObj.stop();
          } else { self.validation.step1 = true; }
          break;
        default:
          break;
      }
    });
  },
  methods: { 
    updateColorValue(val) {
      this.color = val.hex;
    },
    submit() {
      if (this.color.length !== 7) {
        this.validation.step2 = false;
        return;
      }
      this.validation.step2 = true;

      this.isSubmitting = true;
      const postData = {
        title: JSON.stringify(this.title),
        color: this.color,
      }
      
      ApiService.post('ribbon/add.req.php', postData)
      .then(() => {
        setTimeout(() => {
          this.isSubmitting = false;
          this.$router.push({ name: "ribbons" });  
        }, 1000);
      })
      .catch(({ response }) => {
        setTimeout(() => {
          this.isSubmitting = false;
          alert(response.data.errorMessage);
        }, 1000);
      });

    },
  }
}
</script>

<style scoped>
.vc-chrome {
  position: absolute;
  border-radius: 0.85rem !important;
  width: 50% !important;
}
</style>
<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-2.scss";

.wizard.wizard-2 .wizard-nav .wizard-steps .wizard-step {
  border: 2px solid transparent;
  border-radius: 0.85rem;
}
.wizard.wizard-2
  .wizard-nav
  .wizard-steps
  .wizard-step[data-wizard-state='current']:after {
  display: none;
}
.vc-chrome-color-wrap {
  display: none !important;
}
.vc-chrome-alpha-wrap {
  display: none !important;
}
.vc-chrome-body {
  border-radius: 0.85rem !important;
}
.vc-chrome-saturation-wrap {
  border-radius: 0.85rem 0.85rem 0 0 !important;
}
</style>